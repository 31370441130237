import React, { Component } from 'react';
import styled from '@emotion/styled';
import { Layout } from 'layouts';
import {
  PageHeader,
  TechnologyPartners,
  BrandsWorkedWithUs,
} from '@components';
import headerImage from '@static/header/technologies.png';
import headingIcon from '@static/icons/Industries_heading_icon.svg';
import Heading from '@components/pageHeading';
import PageNavigation from '@components/pageNavigation';
import pageContent from '@constants/technologies';
import { applicationURLs } from '../routes';

const TechnologyWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px;

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    width: 90%;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }
`;

const TechnologySection = styled.div`
  display: flex;
  max-width: 1000px;
  margin: 40px 0;
  position: relative;
  flex-direction: row;
  align-items: flex-start;

  @media (max-width: ${(props) => props.theme.breakpoints.m}) {
    flex-direction: column;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  position: relative;
  width: 50%;

  @media (max-width: ${(props) => props.theme.breakpoints.m}) {
    width: 100%;
  }
`;

const TechWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 100px;
  position: relative;
  width: 50%;
  flex-wrap: wrap;

  @media (max-width: ${(props) => props.theme.breakpoints.m}) {
    width: 100%;
    flex-direction: column;
    padding: 0;
    margin-top: 20px;
    max-height: 270px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 55px;
    left: 40px;
    height: 2px;
    top: 10px;
    background: ${(props) => props.theme.colors.primary.base};
    @media (max-width: ${(props) => props.theme.breakpoints.m}) {
      display: none;\
    }
  }
`;

const TechnogyTitle = styled.h2`
  font-size: 34px;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    justify-content: center;
  }
`;

const TechnlogyTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: self-start;

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    flex-direction: column;
  }
`;

const TechnologyText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: self-start;
  box-sizing: border-box;
  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    align-items: center;
    width: 100%;
  }
`;

const Text = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.colors.black.base};
  margin-bottom: 10px;

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    text-align: center;
  }
`;

const ExploreButton = styled.a`
  display: inline-block;
  font-size: 13px;
  color: ${(props) => props.theme.colors.white.base};
  background: ${(props) => props.theme.colors.primary.base};
  font-weight: bold;
  height: 32px;
  line-height: 30px;
  border-radius: 16px;
  width: 100px;
  text-align: center;
  transition: all 0.4s;

  &:hover {
    color: ${(props) => props.theme.colors.white.base};
    background: ${(props) => props.theme.colors.primary.light};
  }
`;

const TechonologyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: self-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  padding-left: 65px;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    position: relative;
    width: 50%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    align-items: center;
  }
`;

const TechnologyList = styled.li`
  font-size: 16px;
  list-style-type: none;
  margin-bottom: 5px;
  color: ${(props) => props.theme.colors.black.light};
  font-weight: bold;
  flex-basis: 50%;

  &:first-of-type {
    color: ${(props) => props.theme.colors.primary.base};
  }
`;

class Technologies extends Component {
  render() {
    return (
      <Layout themeType="dark">
        <PageHeader
          image={headerImage}
          title={'Technologies'}
          icon={headingIcon}
        />
        <Heading title={pageContent.heading} overview={pageContent.overview} />

        {this.renderPageTechnologies()}

        <PageNavigation
          prev={pageContent.navigation.prev}
          prevLink={applicationURLs.TECHNOLOGIES}
          next={pageContent.navigation.next}
          nextLink={applicationURLs.PROCESS}
        />

        <TechnologyPartners />

        <BrandsWorkedWithUs noBackground />
      </Layout>
    );
  }

  renderPageTechnologies() {
    return (
      <TechnologyWrapper>
        {pageContent.technology.list.map((tech, idx) => {
          let Paragraphs = tech.text.split('\n');
          return (
            <TechnologySection key={idx}>
              <InfoWrapper>
                <TechnogyTitle>{tech.title}</TechnogyTitle>
                <TechnlogyTextWrapper>
                  <TechnologyText>
                    {Paragraphs.map((paragraph, idx) => (
                      <Text key={idx}>{paragraph}</Text>
                    ))}
                  </TechnologyText>
                </TechnlogyTextWrapper>
                <ExploreButton href="/">Get a quote</ExploreButton>
              </InfoWrapper>
              <TechWrapper>
                {tech.subTechnology.map((item, index) => (
                  <TechnologyList key={index}>{item.title}</TechnologyList>
                ))}
              </TechWrapper>
            </TechnologySection>
          );
        })}
      </TechnologyWrapper>
    );
  }
}

export default Technologies;
